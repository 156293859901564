.title {
  font-weight: 700;
  @media (--desktop) {
    margin-top: var(--spacing-1);
  }
}

.intro {
  margin: 0 0 var(--spacing-2);
}

.subtitle {
  margin-top: 24px;
}

.fields {
  > div {
    margin: var(--spacing-4) 0 var(--spacing-6);
  }
}

.collectionTitle {
  margin: var(--spacing-2) 0 var(--spacing-4);
}

.collectionIntro {
  margin-bottom: 26px;
}

.termsTitle {
  margin: -4px 0 var(--spacing-4) 0;
}

.radio {
  > div {
    margin: var(--spacing-6) 0 var(--spacing-8);
  }

  & label:first-child {
    margin-bottom: var(--spacing-6);
  }
}
.divider {
  color: var(--color-neutral-300);
  margin-bottom: 0 !important;
}

.termsField > * {
  margin-top: -1.8rem !important;
  @media (--mobile) {
    margin-top: 0;
  }
}

.termsField label {
  font-size: 16px;

  & a {
    color: var(--color-primary-500);
  }
}

.termsField > :first-child {
  margin-top: 0 !important;
}

.termsField > :not(:first-child) label > div > input:nth-child(1) {
  margin-top: var(--spacing-1);
  @media (--mobile) {
    margin-top: var(--spacing-5);
  }
}
.termsField label > div > input:nth-child(1) {
  margin-top: var(--spacing-1);
}

.privacyPolicy > p {
  font-size: 12px;
}
